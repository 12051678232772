/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import 'select2/dist/js/select2.full.min';
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min';
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min";
import Dropzone from 'dropzone/dist/min/dropzone.min';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);

Dropzone.prototype.defaultOptions.maxFilesize = 100;
Dropzone.prototype.defaultOptions.uploadMultiple = true;
Dropzone.prototype.defaultOptions.init = function(){
    this.on('successmultiple', function () {
        var className = this.element.className;
        if(className.includes('showAlertDropzone'))
            alert('File caricati correttamente, si prega di salvare per rendere effettive le modifiche.');
    });
};

$(document).ready(function(e){

    initSelect2Datepicker();

    $('.datatable').dataTable({
        "language": {
            "lengthMenu": "Mostra _MENU_ risultati per pagina",
            "zeroRecords": "Nessuno trovato - spiacente",
            "info": "Mostro pagina _PAGE_ di _PAGES_",
            "infoEmpty": "Nessun record disponibile",
            "infoFiltered": "(filtrati da _MAX_ record totali)",
            "search": "Cerca",
            "infoPostFix":    "",
            "loadingRecords": "Ricerca in corso",
            "emptyTable":     "Nessun dato disponibile nella tabella",
            "paginate": {
                "first":      "Prima",
                "previous":   "Precedente",
                "next":       "Successiva",
                "last":       "Ultima"
            },
            "aria": {
                "sortAscending":  "Ordina ascendente",
                "sortDescending": "Ordina discendente"
            }
        },
        "columnDefs": [
            { orderable: false, targets: -1 },
            { bSearchable: false, targets: -1 }
        ],
        "pageLength": 100
    });

    $('.alert-flash').fadeOut(5000);

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });
});

jQuery(function ($) {

    $(".sidebar-dropdown > a").click(function() {
        $(".sidebar-submenu").slideUp(200);
        if (
            $(this)
            .parent()
            .hasClass("active")
        ) {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
            .parent()
            .removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
            .next(".sidebar-submenu")
            .slideDown(200);
            $(this)
            .parent()
            .addClass("active");
        }
    });

    $("#close-sidebar").click(function() {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
        $(".page-wrapper").addClass("toggled");
    });

});

$(document).on('click', '[data-toggle="card-collapse"]', function (e) {
    let $card = $(this).closest('div.card');

    $card.toggleClass('card-collapsed');
});

$(document).on('focusout', '#MemberType_fiscal_code', function(){
    var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
    // creo una variabile per richiamare con facilità il nostro campo di input
    var CodiceFiscale = document.getElementById("MemberType_fiscal_code");
    // utilizzo il metodo search per verificare che il valore inserito nel campo
    // di input rispetti la stringa di verifica (pattern)
    if (CodiceFiscale.value.search(pattern) == -1)
    {
        // In caso di errore stampo un avviso e pulisco il campo...
        $('#MemberType_fiscal_code').addClass('borderRed');  
    }else{
        // ...in caso contrario stampo un avviso di successo!
        $('#MemberType_fiscal_code').removeClass('borderRed');             
    }
})

$(document).on('focusout', '#member_edit_fiscal_code', function(){
    var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
    // creo una variabile per richiamare con facilità il nostro campo di input
    var CodiceFiscale = document.getElementById("member_edit_fiscal_code");
    // utilizzo il metodo search per verificare che il valore inserito nel campo
    // di input rispetti la stringa di verifica (pattern)
    if (CodiceFiscale.value.search(pattern) == -1)
    {
        // In caso di errore stampo un avviso e pulisco il campo...
        $('#member_edit_fiscal_code').addClass('borderRed');  
    }else{
        // ...in caso contrario stampo un avviso di successo!
        $('#member_edit_fiscal_code').removeClass('borderRed');             
    }
})

function initSelect2Datepicker(){
    $('.select-select2').select2({
        theme: 'bootstrap4',
    });
    $('.datepicker').datepicker({
        language: 'it',
        format: 'dd-mm-yyyy'
    });
    $('.datepicker-year').datepicker({
        minViewMode: 2,
        format: 'yyyy',
        language: 'it'
    });
    $('.datepicker-month').datepicker({
        minViewMode: 1,
        format: 'mm-yyyy',
        language: 'it'
    });
    $('.select-select2-certificate').select2({
        theme: 'bootstrap4',
        dropdownParent: $('#create_certificate')
    });
    $('.select-select2-payment').select2({
        theme: 'bootstrap4',
        dropdownParent: $('#create_payment')
    });
}

export { Routing, Dropzone, initSelect2Datepicker };